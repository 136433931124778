import { useCallback, useMemo } from 'react'

import { observer } from 'mobx-react'
import { useAppStoreContext } from '@store/RootStore'
import ModalWrapper from '@components/modal/ModalWrapper'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { openExternalUrl } from '@src/interop'

import { formatGTMEvent, GTMEvent, IMarketingOptInEvent } from '@utils/gtm-event'
import ENV from '@constants/env'
import TagManager from 'react-gtm-module'

export const ModalOptIn = () => {
    const { modal: modalStore, feed: feedStore, gtmInitialized } = useAppStoreContext()
    const { data } = modalStore

    const plugin = useMemo(() => {
        return feedStore.plugins.get(data.pluginAppId)
    }, [data.pluginAppId])

    if (!plugin) return

    const confirmClick = useCallback(() => {
        if (ENV.VITE_GOOGLE_TAG_MANAGER && gtmInitialized) {
            const gtmPluginMarketingOptInArgs = formatGTMEvent(GTMEvent.PLUGIN_MARKETING_OPT_IN, {
                pluginAppId: plugin.appId,
                pluginName: plugin.name,
                labelName: plugin.label.name,
            } as Partial<IMarketingOptInEvent>)
            TagManager.dataLayer({ dataLayer: gtmPluginMarketingOptInArgs.dataLayer })
        }

        plugin.consentAndInstall()
        modalStore.closeModal()
    }, [plugin])

    const cancelClick = useCallback(() => {
        modalStore.closeModal()
    }, [])

    const openURLCallback = useCallback((url = '', title = '') => {
        return () => {
            openExternalUrl(url, title)
        }
    }, [])

    return (
        <ModalWrapper>
            <div className="mx-auto text-flp-text select-none">
                <h1 className="flp-header text-flp-grey-2 pt-4 text-center">FL Cloud partner special</h1>
                <div className="text-16px text-flp-text py-4">
                    <span className="text-flp-white">{plugin.name}</span> is an FL Cloud &amp; Friends free plugin for opt-in subscribers to{' '}
                    {plugin.label?.name} marketing emails. To get this free plugin, please accept the terms and conditions. You will be automatically
                    signed up for {plugin.label.name} marketing communications. You may unsubscribe from these at any time without losing access to{' '}
                    <span className="text-white">{plugin.name}</span>.
                </div>
            </div>
            <div className="text-flp-text text-11px">
                <span className="text-flp-white">Terms &amp; conditions</span>
                <OverlayScrollbarsComponent element="section" className="flp-custom-scrollbar max-h-72px" defer>
                    <h3>Consent required for {plugin.name}</h3>
                    By agreeing to use the {plugin.name} plugin, you hereby consent to the sharing of your Image Line email account address with{' '}
                    {plugin.label?.name}. Please know that you can always opt-out to such communications by clicking the "unsubscribe" button
                    available in {plugin.label?.name} emails{' '}
                    {plugin.label?.contactUrl ? (
                        <>
                            or by reaching out to their website's contact form available at{' '}
                            <span className="underline" onClick={openURLCallback(plugin.label?.contactUrl, '')}>
                                {plugin.label?.contactUrl}
                            </span>
                        </>
                    ) : null}
                </OverlayScrollbarsComponent>
            </div>

            <div className="flex gap-10 pt-8 justify-center">
                <div
                    id="button-cancel"
                    className="flp-button group bg-flp-button-grey-background text-flp-button-grey-text border-flp-button-grey-background"
                    onClick={cancelClick}>
                    Cancel
                </div>
                <div
                    id="button-confirm"
                    className="flp-button group bg-flp-button-yellow-background border-flp-button-yellow-background-highlight text-flp-button-yellow-text hover:bg-flp-button-yellow-background-highlight hover:border-transparent"
                    onClick={confirmClick}>
                    Accept &amp; Install
                </div>
            </div>
        </ModalWrapper>
    )
}

export default observer(ModalOptIn)
